export const apiKey = 'ELC418kI1l7LemEPImSbp5tcMndO8E9v1vNcwvLM'
export const stage = 'test'
export const domain = `https://api.${stage}.tis.coke.com`
export const employee = `/employees`
export const formula = `/komix-service/formulas`
export const ingredient = `/komix-service/ingredients`
export const picasso = `/komix-service/picasso`
export const outputDocument = `/komix-service/output-documents`
export const region = `/regions`
export const azureClientId = '09e53e25-732e-44a9-961d-1e28abae2d3d'
export const azureTenantId = 'eb36d020-2c35-44de-bc7a-554cc979b26d' 